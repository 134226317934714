
import {
  onMounted,
  onUnmounted,
  defineComponent,
  inject,
  reactive,
  ref,
  watch,
} from "vue";
import { PageParams } from "@/apis/interface/PageParams";
import { RespBody, RespBodyPager } from "@/apis/interface/Resp";
import { IUser, UserFilter, UserModel } from "@/apis/interface/IUser";
import bus from "@/utils/bus";
import DialogRole from "./DialogRole.vue";
import { ElMessage } from "element-plus";

export default defineComponent({
  name: "List",
  components: {
    DialogRole: DialogRole,
  },
  setup() {
    const apiUser = inject("apiUser") as IUser;
    const dialogRole = ref(null);

    const pageParams: PageParams = reactive({
      aaIndex: 1,
      aaSize: 50,
    });

    const filter: UserFilter = reactive({
      companyId: null,
      username: "",
      realname: "",
      phone: "",
      gender: null,
    });

    let clearFilter = () => {
      filter.companyId = null;
      filter.username = "";
      filter.realname = "";
      filter.phone = "";
      filter.gender = null;
      pageParams.aaIndex = 1;
      loadData();
    };

    let dataList = ref<UserModel[]>([]);
    let loading = ref(false);

    onUnmounted(() => {
      bus.off("on-table-row-edit-ok");
    });

    let loadData = async () => {
      loading.value = true;
      dataList.value = [];
      const resp = await apiUser.getList(pageParams, filter);
      const pager: RespBodyPager<UserModel> = resp as RespBodyPager<UserModel>;
      pageParams.aaSize = pager.object.pageSize;
      pageParams.aaIndex = pager.object.pageCurrent;
      pageParams.total = pager.object.totalCount;
      dataList.value = pager.object.listData;
      // console.log("==>", dataList);
      loading.value = false;
    };

    let clickCreate = () => {
      bus.emit("on-table-row-edit", {
        dataList: [],
        dataRow: new UserModel({}),
      });
    };

    let statusFormat = (status: number) => {
      // console.log(status);
      return "正常";
    };

    onMounted(() => {
      loadData();
      bus.on("on-table-row-edit-ok", () => {
        loadData();
      });
    });

    let deleteRow = async (id: number) => {
      let result = (await apiUser.remove([id])) as RespBody<number>;
      if (result.object > 0) {
        ElMessage({
          type: "success",
          message: "刪除成功！",
        });
        await loadData();
      } else
        ElMessage({
          type: "error",
          message: "刪除失敗",
        });
    };

    watch(
      () => pageParams.aaIndex,
      () => {
        loadData();
      }
    );
    watch(
      () => pageParams.aaSize,
      () => {
        pageParams.aaIndex = 1;
        loadData();
      }
    );

    let tableHeight = ref(0);

    let onClickShowRole = (user: UserModel) => {
      (dialogRole.value as any).setData(user);
    };

    return {
      loading,
      dialogRole,
      tableHeight,
      pageParams,
      filter,
      dataList,
      statusFormat,
      clickCreate,
      loadData,
      onClickShowRole,
      deleteRow,
      clearFilter,
    };
  },
});
